<template>
  <div>
    <CCard class="col-md-12">
      <CRow class="p-3">
       <h4 class="color-light ml-2 page_title"><span class="ul_text">Inspec</span>tor</h4>
       </CRow>
      <CCardBody>
        <!-- <CRow>
        <label class="col-md-11" style="">Use the View Logs to get all the data about a visit.
            Be sure to search for the fraudAvoid clickid. You can also
            consult it programmatically <a href="">using our api</a></label>
        </CRow> -->
        <CRow>
          <CInput
            class="col-md-3 col-12"
            type="text"
            size="sm"
            v-model="clickfind"
            require
            placeholder=""
          ></CInput>
          <CButton
            class="col-md-1 col-12 mb-3"
            size="sm"
            type="submit"
            @click="onSubmit('data')"
            variant=""
            color="success"
            >Search</CButton
          >
        </CRow>
        <div v-if="loading_data==true">Loading . . .</div>
        <div v-else>
        <CRow>
          <div class="col-md-12">
            <!-- <pre>{{iwitness}}</pre> -->
            <!-- <CButton v-show="show_video_button == true" class="mt-5 ml-2 col-md-2 col-12 mb-3"  size="sm" type="submit" @click="onSubmit('video')" variant="" color="info">Fetch Video</CButton> -->
            <!-- <span v-show="show_text">{{items}}</span> -->
            <CDataTable
              :items="items"
              :fields="fields"
              :dark="false"
              :items-per-page="tableperpage"
              pagination
              sorter
              hover
              striped
              border
              small
              fixed
              light
            >
              <template #play="{ item }">
                <td class="link">
                  <img
                    src="img/Play.png"
                    height="30"
                    @click="openModal(item)"
                  />
                </td>
              </template>
              <template #duration="{ item }">
                <td>{{ item.duration }}</td>
              </template>
            </CDataTable>
          </div>
        </CRow>
        </div>
      </CCardBody>
    </CCard>
    <!-- Show Model -->
    <CModal
      title="Video"
      :show.sync="video_modal"
      size="xl"
      :centered="true"
      scrollable
      color="dark"
    >
      <div>
        <center>
          <span v-show="show_video_button == true" @click="onSubmit('video')">
            <img src="img/Play.png" class="mt-5" height="150" />
            <h2><b>Click here to Fetch Video </b></h2>
          </span>
          <video
            class="video_tag"
            v-show="show_video"
            ref="videoRef"
            src=""
            id="videocontainer"
            height="90%"
            controls
            style="max-height: 70vh;width: -webkit-fill-available;"
          ></video>
          <h3 v-if="show_no_video_message">{{ no_video_message }}</h3>
          <h3 v-if="show_no_data_message">{{ no_data_message }}</h3>
          <CElementCover
            v-show="loading"
            :boundaries="[{ sides: ['top', 'left'], query: '.media-body' }]"
            :opacity="0.8"
          >
            <h1 class="d-inline">Loading...</h1>

            <CProgress class="mb-3">
              <CProgressBar
                color="success"
                variant="striped"
                animated
                :value="parseInt(uploadVStatus)"
              />
            </CProgress>
          </CElementCover>
        </center>
      </div>
      <template #footer>
        <CRow class="col-md-12">
          <CCol>
            <center>
             <span class="link" v-show="show_video" @click="Skip()">Skip to next Activity</span>
             <span class="ml-3" v-show="show_video" @click="copyUrl('videoUrl')">
              <CIcon class="icon_color" :height="25" name="cil-copy" title="copy Url" ></CIcon>
             </span>
             <span v-show="show_video">
             <span v-if="fav_video" class="link" style="float:right;font-size:25px;color: #0068ff;" title="remove from favorite" @click="RemoveFromFav(vdata.uniq_id)">&#9733;</span>
             <span v-else class="link" style="float:right;font-size:25px;color: #b9abab;" title="add to favorite" @click="AddtoFav(vdata)">&#9733;</span>
             </span>
             </center>

          </CCol>
        </CRow>
        <CInput class="" type="text" id="videoUrl" v-model="videoUrl" hidden></CInput>
       
      </template>
    </CModal>
  </div>
</template>

<script>
import Iwitness from "../../services/iwitnessController";
import Swal from "sweetalert2/dist/sweetalert2.js";
import 'sweetalert2/src/sweetalert2.scss';
import ClickFinderService from '../../services/clickfinderControlService'
import HelperFunction from "./../../helper/helperFunction";
let socket;
export default {
  components: {},
  async mounted() {
    if (
      window.localStorage.getItem("user") &&
      window.localStorage.getItem("status") != "true" || window.localStorage.getItem("iwitness") == 'false'
    ) {
      this.$router.push("/");
    } else {
      if (window.localStorage.getItem("user") == "null") {
        alert("Please Enter User Name and Password");
        this.$router.push("Login");
      } else {
        if(this.$root.$data.iwitness_id && this.$root.$data.iwitness_id != ""){
          this.clickfind = this.$root.$data.iwitness_id;
          this.onSubmit('data');
        }
        this.user_status = window.localStorage.getItem("status");
      }
    }
  },
  data() {
    return {
      campData:{},
      fav_video:false,
      videoUrl:'',
      loading_data:false,
      uploadVStatus: 0,
      loading: false,
      user_status: "",
      clickfind: "",
      iwitness: "",
      show_video: false,
      show_video_button: false,
      show_no_video_message: false,
      show_no_data_message: false,
      no_video_message: "No Video Available !!!",
      no_data_message: "No data available !!!",
      items: [],
      tableperpage: 50,
      fields: [
        { key: "uniq_id", label: "Id", sortable: true },
        { key: "url", label: "Url", sortable: true },
        { key: "duration", label: "Duration", sortable: true },
        { key: "play", label: "Play", sortable: true },
      ],
      show_text: false,
      video_modal: false,
      vdata: [],
      timedata:[]
    };
  },
  methods: {
    async AddtoFav(data){
      let payload={
        campname : this.campData.campname,
        url : data.url
      }
      let response = await Iwitness.addFav(data.uniq_id,payload);
    if(response.result){
        this.fav_video = true
        this.getFav();
      } 
    },
    async RemoveFromFav(id){
      let response = await Iwitness.deleteFav(id);
      if(response.result){
        this.fav_video = false
        this.getFav();
      }
    },
    async getFav(){
      this.fav_video = false
      let getfav = await Iwitness.getFav();
      getfav.data.map((t)=>{
        if(t._id==this.vdata.uniq_id){
          this.fav_video = true
        }
      })
      this.$emit('getFav')
    },
    copyUrl(txt) {
      var textField = document.getElementById(txt);
      textField.select();
      textField.focus(); //SET FOCUS on the TEXTFIELD
      navigator.clipboard.writeText(textField.value);
      Swal.fire({
        title: "Success!",
        text: "URL Copied!!",
        icon: "success",
        confirmButtonText: "Ok",position: 'top-end',showConfirmButton: false,timer: 1000
      });
    },
    Skip(){
    var key = 0;
    var x = 0;
    var vid =  document.getElementById('videocontainer');
    vid.pause()
    var ctime=vid.currentTime;
    var sum = 0;
    var i;
    for(i=0;i<=this.timedata.length;i++){
    sum +=this.timedata[i];
    if(sum> ctime){
    key = i;
    break;
    }
    }
    if(key > this.timedata.length-1){
    console.log('end')
    key=0;
    x=0;
    }
    else{
    x=0;
    for(i=0;i<=key;i++){
    x +=this.timedata[i];
    }
    key ++;
    }
    vid.currentTime = x
    vid.play();
    },
    async onSubmit(txt) {
      try {
        this.items = [];
        if(HelperFunction.validateName(this.clickfind,250) == false || this.clickfind == ""){
          Swal.fire({title: "Error!",text: "Please enter valid External Id",icon: "error",position: 'top-end',showConfirmButton: false,timer: 2000});
          return false;
        }
        this.videoUrl= '';
        this.$refs.videoRef.loop = true;
        this.show_no_video_message = false;
        this.$root.$data.iwitness_id = this.clickfind;
        if (this.clickfind != "") {
          this.show_no_data_message = false;
          if (txt == "data") {
            this.show_video = false;
            this.loading_data = true;
            let response = await Iwitness.iwitnessdatafind(this.clickfind);
            this.uploadVStatus = 0;
            if (response.result) {
              this.items = response.message;
              this.show_text = true;
              // this.show_video_button = true;
              this.loading_data = false;
            } else {
              Swal.fire({
                title: "Error!",
                text: "Data Not Available !!!",
                icon: "error",
                confirmButtonText: "Close",position: 'top-end',showConfirmButton: false,timer: 1000
              });
              this.loading_data = false;
              this.show_video_button = false;
              this.show_no_data_message = true;
            }
          }
          if (txt == "video") {
            try {
              if (socket.readyState == WebSocket.OPEN) {
                socket.close();
              }
            } catch (e) {
              console.log("ERROR: error in check status" + e);
            }

            socket = new WebSocket(
              process.env.VUE_APP_SOCKETURL + "/" + this.vdata.uniq_id
            );
            let p;
            let clickid = this.vdata.uniq_id;

            socket.onopen = function (e) {
              console.log("[open] Connection established");
              console.info("Sending to server");
              let data = {
                event: "vstatus",
                data: clickid,
              };
              socket.send(JSON.stringify(data));
            };
            socket.onerror = function (error) {
              console.error("error:", error);
              throw new Error("Unable to Open Socket");
            };
            socket.onclose = function (e) {
              console.log("[close] connection closing");
            };
            socket.onmessage = async function (event) {
              //  console.log(event)
              //  console.log("received data"+event.data)
              try {
                let response = JSON.parse(event.data);

                this.uploadVStatus = response.status;
                if (this.uploadVStatus == "100") {
                  this.show_video_button = false;
                  this.loading = false;
                  if (response.videoUrl) {
                    socket.close(1000, "Work complete");
                    this.show_video = true;
                    this.$refs.videoRef.src = response.videoUrl;
                    this.$refs.videoRef.play();
                    this.videoUrl = this.$refs.videoRef.src
                    let data = await Iwitness.eventlist(this.vdata.uniq_id);
                    this.timedata = data.message.eventloopArray;
                  } else {
                    socket.close(1000, "Work complete");
                    Swal.fire({
                      title: "Error!",
                      text: "Video Not Available !!!",
                      icon: "error",
                      confirmButtonText: "Close",position: 'top-end',showConfirmButton: false,timer: 1000
                    });
                    this.show_no_video_message = true;
                    this.show_video_button = false;
                    this.show_video = false;
                  }
                }
              } catch (e) {
                console.log(e);
              }
            }.bind(this);

            this.show_text = false;
            this.loading = true;
            let response = await Iwitness.iwitnessvideofind(this.vdata.uniq_id);
          }
        }
      } catch (e) {
        // this.errorNotify("Error", "connection error", "danger");
      }
    },
    openModal(data) {
      this.getCampdata(data.uniq_id)
      if(data.duration.split(":")[0]>20){
        Swal.fire({
          title: "Error!",
          text: "Video Duration must be less than 20 minutes !!!",
          icon: "error",
          confirmButtonText: "Close",position: 'top-end',showConfirmButton: false,timer: 1000
        });
      }
      else{
      this.video_modal = true;
      this.show_video_button = true;
      this.vdata = data;
      this.show_video = false;
      this.$refs.videoRef.src = "";
      this.uploadVStatus = 0;
      this.loading = false;
      this.onSubmit('video')
      }
    },
    async getCampdata(id){
      let find_data = {
          clickid: id
      }
      let response = await ClickFinderService.clickfind(find_data)
      this.campData = response.message
  },
  },
  watch: {
    uploadVStatus: function () {},
  },
};
</script>

<style scoped>
.video_tag {
  border: 2px solid #0000004d;
}
/* @media screen and (max-width: 768px ) {
  .video_tag{
  max-height: 90% !important;
  min-height: 90% !important;
} */
/* } */
h1 {
  font-size: 30px;
  color: #0b1218;
  margin-bottom: 24px;
}
.data {
  margin: 4px, 4px;
  margin-bottom: 20px;
  padding: 4px;
  width: auto;
  min-height: 100px;
  max-height: auto;
  background-color: #f3ecec;
}
label {
  text-align: left;
  font-weight: 400;
  color: #6e7d8f;
  font-size: 12px;
}
.b_card {
  background-color: #fbf9f9;
}
.bg_page {
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 20px;
  /*position: fixed;*/
  /*background: rgb(2, 0, 36);*/
  /*background: linear-gradient(152deg, rgba(2, 0, 36, 1) 0%, rgba(15, 15, 126, 1) 49%, rgba(0, 212, 255, 1) 100%);*/
}

.logout {
  padding: 4px;
  background-color: #ff374c;
  text-align: center;
  border-radius: 5px;
}
</style>

import api from './api'
let video_url = process.env.VUE_APP_BASEURL
const getBasePath = () => {
  return window.localStorage.getItem("user") === 'csp' ? '/csp/iwitness' : '/iwitness'
}

export default {
  videourl : video_url,
  iwitnessdatafind(data) {
    return api.execute('get', `${getBasePath()}/data/${data}`)
  },
  iwitnessvideofind(data) {
    return api.execute('get', `${getBasePath()}/video/${data}`)
  },
  eventlist(data) {
    return api.execute('get', `${getBasePath()}/eventlist/${data}`)
  },
  addFav(data, payload) {
    return api.execute('post', `${getBasePath()}/fav/${data}`, payload)
  },
  getFav() {
    return api.execute('get', `${getBasePath()}/fav/`)
  },
  deleteFav(id) {
    return api.execute('DELETE', `${getBasePath()}/fav/${id}`)
  }
}
